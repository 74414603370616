import { get, post } from './http';

const api = {};
let http = 'https://musahc.sustech.edu.cn';
// let http = 'https://musahcapi.sustech.edu.cn';

if (localStorage.getItem('__APP_DEBUG__')) {
	let base_url = localStorage.getItem('__BASE_URL__');
	if (base_url) {
		http = base_url;
	}
}

api.getUrl = url => {
	if (false !== url) {
		if (!url) return '';
		if (!url || /^http(s)?(.*)/i.test(url)) return url;
		return http + (url ? url : '');
	} else {
		return http;
	}
}

// get方式
api.menus = data => get(http + '/index/menus', data); //菜单
api.nav = data => get(http + '/index/nav', data); //菜单
api.banner = data => get(http + '/index/banner', data); //轮播图
// api.banner = data => get(http+'/index/links', data) //友情链接
api.quick = data => get(http + '/index/quick', data); //推荐
api.tuijan = data => get(http + '/index/tuijian', data); //推荐
api.links = data => get(http + '/index/links', data); //友链
api.config = data => get(http + '/index/config', data); //设置
api.news = data => get(http + '/index/news', data); //列表
api.page = data => get(http + '/index/page', data); //单篇
api.detai = data => get(http + '/index/detail', data); //详情
api.category = data => get(http + '/index/category', data); //分类
api.home = data => get(http + '/index/indexdata', data); //首页
api.pid = data => get(http + '/index/pid', data); //获取上面分类

// post方式
api.fournews = data => post(http + '/index/fournews', data); // 获取四级内容
api.signup = data => post(http + 'index/signup', data); //
api.rightmeun = data => get(http + '/index/rightmeun', data); //

export default api;
