import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/index',
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/index.vue")
      }, {
        path: "/details/text/:newsid",
        name: "details",
        component: () => import("../views/details/text.vue")
      }, {
        path: "notice/:newsid",
        name: "notice",
        component: () => import("../views/details/notice.vue")
      }, {
        path: "search/:searchtext",
        name: "search",
        component: () => import("../views/details/search.vue")
      }, {
        path: "/details/cont/:newsid",
        name: "details",
        component: () => import("../views/details/cont.vue")
      }, {
        path: "/bggk",
        name: "bggk",
        component: () => import("../views/bggk/index.vue"),
        children: [{
          path: "/bggk/ggjj/:newsid",
          name: "ggjj",
          component: () => import("../views/bggk/ggjj.vue")
        }, {
          path: "/bggk/listGk/:newsid",
          name: "list",
          component: () => import("../views/bggk/list.vue")
        }, {
          path: "/bggk/sjbb/:newsid",
          name: "sjbb",
          component: () => import("../views/bggk/sjbb.vue")
        }, {
          path: "/bggk/report/",
          name: "report",
          component: () => import("../views/bggk/report.vue")
        }]
      }, {
        path: "/fwdt",
        name: "fwdt",
        component: () => import("../views/fwdt/index.vue"),
        children: [{
          path: "/fwdt/textF/:newsid",
          name: "text",
          component: () => import("../views/fwdt/text.vue")
        }, {
          path: "/fwdt/tableF/:newsid",
          name: "table",
          component: () => import("../views/fwdt/news.vue")
        }, {
          path: "/fwdt/yyjyF/:newsid",
          name: "yyjy",
          component: () => import("../views/fwdt/yyjy.vue")
        }, {
          path: "/fwdt/reservat/",
          name: "reservat",
          component: () => import("../views/fwdt/reservat.vue")
        }]
      }, {
        path: "/zszx",
        name: "zszx",
        component: () => import("../views/zszx/index.vue"),
        children: [{
          path: "/zszx/textz/:newsid",
          name: "nrxq",
          component: () => import("../views/zszx/text.vue")
        }, {
          path: "/zszx/newsz/:newsid",
          name: "news",
          component: () => import("../views/zszx/news.vue")
        }, {
          path: "/zszx/listz/:newsid",
          name: "list",
          component: () => import("../views/zszx/list.vue")
        }]
      }, {
        path: "/gdzn",
        name: "gdzn",
        component: () => import("../views/gdzn/index.vue"),
        children: [{
          path: "/gdzn/text/:newsid",
          name: "nrxq",
          component: () => import("../views/gdzn/text.vue")
        }, {
          path: "/gdzn/newsg/:newsid",
          name: "news",
          component: () => import("../views/gdzn/news.vue")
        }]
      }, {
        path: "/slzj",
        name: "slzj",
        component: () => import("../views/slzj/index.vue"),
        children: [{
          path: "/slzj/text/:newsid",
          name: "nrxq",
          component: () => import("../views/slzj/text.vue")
        }, {
          path: "/slzj/lists/:newsid",
          name: "list",
          component: () => import("../views/slzj/list.vue")
        }]
      }, {
        path: "/daxt",
        name: "daxt",
        component: () => import("../views/daxt/index.vue"),
        children: [{
          path: "/daxt/text/:newsid",
          name: "nrxq",
          component: () => import("../views/daxt/text.vue")
        }, {
          path: "/daxt/newsd/:newsid",
          name: "news",
          component: () => import("../views/daxt/news.vue")
        }]
      }, {
        path: "/gzzd",
        name: "gzzd",
        component: () => import("../views/gzzd/index.vue"),
        children: [{
          path: "/gzzd/text/:newsid",
          name: "nrxq",
          component: () => import("../views/gzzd/text.vue")
        }, {
          path: "/gzzd/listgz/:newsid",
          name: "list",
          component: () => import("../views/gzzd/list.vue")
        }]
      }, {
        path: "/newdetails",
        name: "newdetails",
        component: () => import("../views/newdetails/index.vue"),
        children: [{
          path: "/newdetails/list",
          name: "nrxq",
          component: () => import("../views/newdetails/list.vue")
        }, {
          path: "/daxt/newsd/:newsid",
          name: "news",
          component: () => import("../views/daxt/news.vue")
        }]
      }, {
        path: "/juan_zeng",
        name: "JuanZeng",
        // redirect: '/index',
        // component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
        component: () => import("../views/juan_zeng/index.vue"),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
