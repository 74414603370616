import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const getDefaultState = () => {
	return require('./YellowStoreStates');
};

const store = new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: {
				getItem: key => localStorage.getItem(key),
				setItem: (key, value) => localStorage.setItem(key, value),
				removeItem: key => localStorage.removeItem(key)
			},
			key: '__store_'
		})
	],
	state: getDefaultState(),
	mutations: {
		save: (state, [key, value]) => {
			state[key] = value;
		},
		del: (state, key) => {
			state[key] = null;
		},
		clear: state => {
			var recommend_userid = state.recommend_userid;
			var bag = state.bag;
			var category = state.category;
			var categoryTags = state.categoryTags;
			for (var member in state) delete state[member];
			const initial = getDefaultState();
			Object.keys(initial).forEach(key => {
				state[key] = initial[key];
			});
			state.recommend_userid = recommend_userid;
			state.bag = bag;
			state.category = category;
			state.categoryTags = categoryTags;
		}
	},
	actions: {
		save({ commit }, arr) {
			commit('save', arr);
		},
		del({ commit }, key) {
			commit('del', key);
		},
		clear({ commit }) {
			commit('clear');
		}
	}
});

export default store;
