import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/YellowStore';
import ViewUI from 'view-design';
import './assets/css/style.css';
import './assets/css/iview.css';
Vue.config.productionTip = false;
import VCharts from 'v-charts';
import api from '../src/api/index'; // 导入我们的api接口
Vue.prototype.$api = api;
import 'swiper/swiper-bundle.css';
import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);
import VueAwesomeSwiper from 'vue-awesome-swiper';
import arctype from '@/config/arctype';

Vue.use(VueAwesomeSwiper);
Vue.use(VCharts);
Vue.use(ViewUI);

Vue.prototype.globalData = {
	nav: []
};
Vue.prototype.arctype = arctype;

Vue.mixin({
	data() {
		return {
			windowW: 1024,
			windowH: 768
		};
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResizeFun);
			this.onResizeFun();
			setTimeout(() => {
				this.onResizeFun();
			}, 1000);
		});
	},
	methods: {
		onResizeFun() {
			this.windowW = window.innerWidth;
			this.windowH = window.innerHeight;
		},
		GS(url, size) {
			size = size || 500;
			var ext = url.split('.').pop();
			return url.replace('.' + ext, '_' + size + '.' + ext);
		}
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
