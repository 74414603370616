<template>
	<div id="app">
		<div v-show="loading" class="loadings" style="">
			<div>
				<img src="../public/index-logo.png" alt="" />
				<br />
				<Icon type="ios-loading" size="34" class="demo-spin-icon-load"></Icon>
			</div>
		</div>
		<div v-show="!loading">
			<router-view @getMessage="showMsg" />
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			loading: true,
		};
	},
	watch: {
		$route: 'types',
	},
	methods: {
		showMsg(val) {
			// methods方法  val即为子组件传过来的值
			var that = this;
			that.loading = false;
		},
		types() {
			var that = this;
			that.loading = true;
			// setTimeout(function(){
			// 	that.loading=false
			// },800)
		},
	},
	created() {
		var that = this;
		// setTimeout(function(){
		// 	that.loading=false
		// },800)
	},
};
</script>

<style lang="scss">
.demo-spin-icon-load {
	color: #13b4b1;
	text-align: center;
	margin: 20px 0 0;
	animation: ani-demo-spin 1s linear infinite;
}

.loadings img {
	width: 500px;
}

.loadings {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
}
</style>
