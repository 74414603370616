/**
 * this.arctype.FU_WU_ZHAN_TING
 */
export default {
	FU_WU_ZHAN_TING: 120, //服务大厅>展厅预约>
	GUAN_CANG_JING_PIN: 13, //馆藏精品>
	YING_XIANG: 98, //影像南科大>
	KOU_SHU: 97, //口述南科大>
	NIAN_BAO_NIAN_JIAN: 95, //年报年鉴>
	REN_YUAN_GOU_CHENG: 118, //人员构成
	JIANG_JIE_YUAN1: 158, //讲解员
	JIANG_JIE_YUAN2: 174, //讲解员
	ZHAN_LAN_ZI_XUN: 137, // 展览资讯
	GUI_DANG_ZHI_NAN: 93,//归档指南
	FANG_TAN_LU: 180,//创建访谈录

	WEB_BO_JUAN_ZENG: 143,//创建访谈录

};
